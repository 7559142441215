<template>
    <v-card flat color="transparent">
        <v-toolbar
            dense
            flat
            color="transparent"
            :style="{ marginLeft: '-10px', minWidth: 'calc(100% + 20px)' }"
            >
            <v-toolbar-title >
                <v-icon left>mdi-format-list-bulleted</v-icon>公司列表
            </v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
            <v-row no-gutters>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="4"
                    xl="3"
                >
                    <v-card
                        :height="250"
                        :to="{name:'AppList', params:{companyRoute:companyRoute}}"
                        class='mr-4 mb-4'
                    >
                        <v-img
                        src="/img/no-image.png"
                        :height="250"
                        class="white--text align-end"
                        gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.3)"
                        >
                        <v-card-title >{{ companyName}}</v-card-title>
                        </v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name:"CompanyList",
    computed:{
        companyRoute(){
            return this.$store.state.auth.userInfo.employedBy.route
        },
        companyName(){
            return this.$store.state.auth.userInfo.employedBy.name
        }
    }
}
</script>

<style>

</style>